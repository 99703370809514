const dict = {
  common: {
    siteTitle: "データミックス マイページ",
    email: "メールアドレス",
    password: "パスワード",
    profile: "プロフィール",
    basicInfo: "お客様情報",
    companyInfo: "勤務先情報",
    settings: "設定",
    account: "アカウント",
    logout: "ログアウト",
    login: "ログイン",
    toLogin: "ログイン画面へ",
    toTop: "トップへ",
    signup: "新規登録",
    signupExistingStudent: "既存受講生の方のご登録",
    passwordReset: "パスワードのリセット",
    verificationExpired: "メールアドレスの認証",
    forNonMemberPre: "初めてデータミックスの講座を申込む方は",
    forNonMemberLink: "新規登録（無料）",
    forNonMemberPost: "をお願いします",
    forMember: "登録済みの方のログインはこちら",
    forPasswordForgot: "パスワードをお忘れの方はこちら",
    receiveMail: "メールを受け取る",
    save: "保存する",
    update: "変更する",
    coursePeriod: "開講期間",
    totalPrice: "総額（税込）",
    eachPrice: "内訳金額",
    application: "お申込み",
    applicationDueDate: "お申込み期日",
    apply: "申し込む",
    opening: "開講期間",
    schedule: "スケジュール",
    close: "閉じる",
    next: "次へ",
    back: "戻る",
    sama: "さま",
    samaKanji: "様",
    none: "なし",
    empty: "期間なし",
    detail: "詳細",
    postcode: "郵便番号",
    address: "住所",
    cancel: "キャンセル",
    ok: "OK",
    and: "及び",
    free: "無料",
    place: "開催場所",
    holdingSchool: "開催校",
    notForIE:
      "このサイトは Internet Explorer に対応しておりません。 他のブラウザが利用できないといったご事情がある場合、データミックススクール事業部までご連絡ください。 admission@datamix.co.jp",
  },
  form: {
    required: "必須項目です",
    requiredShort: "必須",
    minLen6: "6文字以上の長さにしてください",
    email: "メールアドレスを入力してください",
    kanaOnly: "全角カタカナにて入力してください",
    hiraKanaKanjiOnly: "全角ひらがな、全角カタカナ、漢字にて入力してください",
    telMobileOnly:
      "携帯電話の番号をハイフンなしで入力してください。例）09000483847",
    telOnly: "電話番号をハイフンなしで入力してください。",
    loginFailedTitle: "ログインに失敗しました",
    loginFailedMessageEmailNotVerified:
      "メールアドレスが認証されていません。データミックスからお送りしているメールにあるリンクから、メールアドレスの認証を行ってください。",
    loginFailedMessageWrongPassword: "パスワードが間違っています。",
    loginFailedMessageUserNotFound:
      "このメールアドレスが紐付いたアカウントがありません。",
    signupFailedTitle: "登録に失敗しました",
    signupFailedMessageEmailAlreadyInUse:
      "このメールアドレスはすでに使われています。ご登録済みの場合、ログインしてください。",
    signupFailedMessageEmailAlreadyInUseAsInitial:
      "このメールアドレスはすでに使われています。もしこのメールアドレスをお持ちの場合、このメールアドレスでご登録された後マイページ内でメールアドレスの変更をされていないかご確認ください。",
    signupSucceededTitle: "メールをご確認ください",
    signupSucceededMessage:
      "ご登録ありがとうございます。ご登録のメールアドレスにお送りしたメールからメールアドレスの認証を行い、登録を完了させてください。",
    signupExistingStudentSucceededTitle: "ご登録ありがとうございます",
    signupExistingStudentSucceededMessage:
      "過去のお客様の情報を紐付ける作業を行い、その後弊社運営からメールにてご連絡いたします。少々おまちください。",
    signupOrderFreeSucceededMessage:
      "ご登録のメールアドレスに確認のメールをお送りします。メール内のリンクをクリックするとお申込みが完了します。",
    passwordResetFailedTitle: "メールを送信できません",
    verificationExpiredFailedTitle: "メールを送信できません",
    verificationExpiredFailedMessageAlreadyVerified:
      "このメールアドレスはすでに認証されています。",
    updateEmailFailedTitle: "メールアドレスを変更できません",
    updateEmailFailedMessageRequiresRecentLogin:
      "最後のログインから時間が経っています。ログアウトしてからログインし、再度変更してください。",
  },
  passwordReset: {
    succeededTitle: "メールをご確認ください",
    succeededMessage:
      "ご登録のメールアドレスにお送りしたメールから、パスワードをリセットしてください。",
    message1:
      "ご登録のメールアドレスを入力してください。パスワードリセット用のメールをお送りします。",
  },
  verifyEmail: {
    verifying: "メールアドレスを認証しています ...",
    succeeded: "マイページへの登録が完了しました。",
    failed:
      "メールアドレスの認証ができません。認証用リンクの期限が切れているか、すでに認証済みのある場合があります。\n期限が切れている場合、認証用リンクを再送できます。下記リンクから処理を行ってください。",
    verificationExpiredLink: "認証用リンクの再送",
  },
  resetPassword: {
    succeededTitle: "パスワードが変更されました",
    succeededMessage: "新しいパスワードでログインできるようになりました。",
    resetToNew: "新しいパスワードを入力してください",
    reset: "変更する",
    failedTitle: "パスワード変更の処理を続行できません",
    failedMessage:
      "リンクの期限が切れているか、リンクが利用済みの可能性があります。\n期限が切れている場合、リンクを再送できます。マイページから再度パスワードリセット用のリンクを取得してください。",
  },
  recoverEmail: {
    checkEmailTitle: "メールをご確認ください",
    checkEmailMessage:
      "下記メールアドレス宛にパスワードリセット用のメールをお送りするので、ご確認ください。",
    emailUpdatedTitle: "メールアドレスをリセットしました",
    emailUpdatedMessage:
      "ご登録のメールアドレスを変更せず、元のメールアドレスのままとしました。\nもしご登録メールアドレスを変更しようとしていない場合、他の誰かがあなたのアカウントにアクセスしようとしている可能性があります。",
    emailUpdateFailedTitle: "メールアドレスをリセットできません",
    emailUpdateFailedMessage:
      "メールアドレスをリセットできません。リンクの期限が切れているか、リンクが利用済みの可能性があります。",
  },
  verificationExpired: {
    succeededTitle: "メールをご確認ください",
    succeededMessage:
      "ご登録のメールアドレスにお送りしたメールから、メールアドレスを認証してください。",
    message1:
      "ご登録時にお送りしたメールアドレス認証のリンクの期限が切れている場合、こちらから再送できます。ご登録のメールアドレスとパスワードを入力してください。",
  },
  basicInfo: {
    lastName: "姓（必須項目）",
    firstName: "名（必須項目）",
    lastNameKana: "セイ（必須項目）",
    firstNameKana: "メイ（必須項目）",
    gender: "性別（必須項目）",
    female: "女性",
    male: "男性",
    birthDate: "生年月日（必須項目）",
    telMobilePhone: "携帯TEL（必須項目）",
    nameEmergency: "緊急連絡先氏名（受講者さま以外）（必須項目）",
    telEmergency: "緊急連絡先TEL（受講者さま以外）（必須項目）",
    noHyphen: " ※ハイフンなし",
    addressInstruction:
      "ご登録住所にテキストや書類を送付しますので、番地・部屋番号までご入力をお願いいたします。",
    companyInfo: "勤務先情報",
    companyName: "勤務先名",
    companyTel: "勤務先TEL",
    companyAddress: "勤務先住所",
  },
  settings: {
    email: "メールアドレスの変更",
    password: "パスワードの変更",
    updateEmailSucceededTitle: "メールアドレスを変更しました",
    updateEmailSucceededMessage:
      "メールアドレスを変更しました。変更後のメールアドレスにお送りしたメールからメールアドレスの認証を行ってください。",
    message1:
      "ご登録のメールアドレスにパスワードリセット用のメールをお送りします。",
    withdrawal:
      "退会をご希望の方は admission@datamix.co.jp までお名前と生年月日を添えてご連絡ください。",
  },
  productList: {
    title: "お申込みTOP",
    filter: "絞り込む",
    search: "講座を選択",
  },
  product: {
    ikuseiTitle: "データサイエンティスト育成講座",
    weatherTitle: "気象データアナリスト養成講座",
    hrTitle: "HRアナリスト養成講座",
    pdaTitle: "プロダクト・データアナリスト育成講座",
    dataEngTitle: "データエンジニア育成講座",
    aimlEngTitle: "生成AI・機械学習エンジニア育成講座",
    dsikuseiTitle: "基礎から学べるデータサイエンティスト育成講座",
    junbiTitle: "データサイエンティスト育成コース準備ステップ",
    junbiDescription:
      "このステップは、データサイエンティストを目指そうと思っているが現在データ分析の経験がなかったり、基礎的な知識が足りないと感じている方や、大規模データを分析する必要がある方、ビッグデータを活用した新規事業を企画される方に向けたステップです。",
    type: "申込みタイプ",
    weekday: "曜日クラス",
    courseHeader: "内容",
    applicationInstruction: "申込みタイプと曜日クラスをお選びください。",
    selectedProduct: "選択中の講座",
    showSchedule: "スケジュールを見る",
    step: "ステップ",
    theme: "テーマ",
    time: "時間",
    remainings: "残席",
    noRemainings: "この講座は満席です",
    selectCombination: "ご希望の講座をお選びください",
    subscriptionInstruction:
      "満席の講座でもキャンセル待ちが可能な場合があります。キャンセル待ちをご希望の場合、admission@datamix.co.jp 宛に希望される講座をお知らせください。",
    combinationNotApplicable:
      "選択された組み合わせでの講座のご提供は行っておりません。",
    selectFromBelow: "下記からお選びください",
    fillApplication: "お申込み",
    apply: "申込む",
    remainingsPlenty: "空席あり",
    remainingsFew: "残りわずか",
    remainings0: "満席",
    // freeProductNewStudentInstruction:
    //   "お申込みに際し、マイページにご登録いただきます。お申込み後お届けするメールにある確認用のリンクをクリックいただくと、マイページへのご登録とお申込みが完了します。",
    freeProductNewStudentInstruction:
        "お申込みに際し、マイページにご登録いただきます。",
    kind: "講座の種類",
  },
  order: {
    studentInfo: "お客様情報",
    orderInfo: "お支払い方法",
    confirm: "確認",
    goToStudentInfo: "お客様情報の入力へ進む",
    prepayment: "銀行振込前払い（ご入金をもってお申込み確定となります）",
    nonPrepayment: "銀行振込後払い・分割払い",
    paymentName: "支払名義",
    oneself: "ご本人さま",
    nonOneself: "ご本人さま以外",
    nameOrCompanyName: "氏名または法人名",
    oneselfInstruction:
      "クラウドローン株式会社が運営する「クラウドローン」（https://crowdloan.jp/）をご利用いただきます。\n上記サービスサイトよりユーザー登録いただくと、金融機関からの融資プランのご提案があります。銀行へ申込を完了させてから、2~3営業日ほどで審査結果の連絡がきます。\nご希望の融資プランを選択後、融資審査通過並びに金融機関からの弊社への入金をもって、お申込み確定となります。",
    company: "ご法人さま（勤務先）",
    nonOneselfInstruction:
      "弊社での所定の手続き完了をもってお申込み確定とし、メールにてご連絡いたします。お申込み確定まで最大5営業日程度かかる場合がございます。必ずご法人さま（勤務先）の情報をご入力ください。",
    paymentNameCompany: "法人名",
    postcode: "郵便番号（必須項目）",
    searchAddress: "住所検索",
    address: "住所（必須項目）",
    kyufukin: "専門実践教育訓練給付金制度の受給申請について",
    requestKyufukin: "申請済み / 申請予定",
    notRequestKyufukin: "申請しない",
    kyufukinInstruction:
      "※ 受給申請は、ハローワークにて別途お手続きください。\n※「申請済み / 申請予定」の方は、前ページで一括申込をご選択ください。\n※ 全ステップ一括でお申込みの場合のみが、受給申請の対象です。",
    fetchAddressFailedTitle: "住所検索ができません",
    fetchAddressFailedMessage404: "郵便番号に合致する住所が見つかりません",
    fetchAddressFailedMessage400: "郵便番号を正しく入力してください。",
    aboutInvoiceAndReceipt:
      "請求書・領収書について\n・請求書・領収書は、お申込み後にマイページのお申込み履歴にてダウンロードすることができます。宛名はご自身で入力することが可能です。\n・ご法人さまによる後払いをご選択の場合の請求書は、弊社からのお申込み確定メール受領後から、マイページにてダウンロードすることができます。宛名はご法人さま名となります。\n・ご本人さまによる分割払い（クラウドローン）をご選択の場合、当社による請求書・領収書は発行いたしません。ただし、お申込金額の一部をクラウドローン経由でお支払いの場合、ご本人さまが弊社へ直接ご入金した分については請求書・領収書は発行可能です。\n・専門実践教育訓練給付金について「申請済み／申請予定」をご選択の場合、マイページにて領収書をダウンロードすることができません。ご自宅住所へ領収書を郵送いたします。",
    note: "備考",
    noteInstruction: "ご連絡事項等がありましたらご記入ください。",
    orderingProduct: "お申込み講座",
    studentName: "お名前",
    productName: "講座名",
    policy: "利用規約",
    agree: "に同意する",
    privacyPolicy: "個人情報の取り扱いについて",
    policyAgreement: "利用規約への同意",
    agreeToPolicy: "利用規約に同意する",
    policyAgreementInstruction:
      "※ 2019年12月20日をもって利用規約の変更を致します。2020年2月期をお申込みの方は上記リンクの新利用規約への同意をお願いいたします。",
    confirmOrder: "お申込みを完了する",
    thankYou: "ありがとうございます",
    thankYouMessage:
      "ご登録のメールアドレス宛にデータミックスからメールをお送りするので、ご確認ください。",
    orderFailedTitle: "お申込みを受け付けられません",
    orderFailedMessageAlreadyOrdered:
      "同じ講座のお申込み履歴があります。お申込みをキャンセルしない限り、同じ講座へはお申込みいただけません。",
    orderFailedMessageTotallyConfirmed:
      "申し訳ありません、選択された講座が満席に達しました。",
    orderFailedMessageNoRemainings:
      "申し訳ありません、選択された講座が満席に達しました。キャンセル時のメール通知依頼をするか、他の曜日クラスを選択してください。",
    orderFailedMessageDuplicated: "すでにお申込み済みの講座が含まれています。",
    orderFailedMessageServerError:
      "大変申し訳ありません、システムエラーが発生しております。現在対応中なので、復旧まで申し訳少々おまちください。",
    // for ordering free products
    orderFreeFailedMessageAlreadyOrdered:
      "すでにお申込みされています。お申込みをキャンセルしない限り、同じ会へはお申込みいただけません。",
    orderFreeFailedMessageTotallyConfirmed:
      "申し訳ありません、選択された会が満席に達しました。",
    orderFreeFailedMessageNoRemainings:
      "申し訳ありません、満席に達しました。キャンセル時のメール通知依頼をするか、他の会を選択してください。",
    orderFreeFailedMessageDuplicated: "すでにお申込み済みです。",

    goToProductList: "一覧へ進む",
    orderListTitle: "お申込み履歴",
    confirmed: "申込み確定",
    canceled: "キャンセル済み",
    ordered: "お申込み済み",
    paymentWaited: "ご入金 / 当社確認待ち",
    evaluationWaited: "審査プロセス中",
    orderedDate: "お申込み日",
    orderDetail: "お申込み詳細",
    orderStatus: "お申込み状況",
    status: "ステータス",
    notCancelable: "キャンセル出来ません",
    notCancelableConfirmed:
      "申込み確定につき、サイト上ではキャンセルを行っていただけません。特別な事情がある場合、メールにてご連絡ください。",
    notCancelableClosed:
      "申込み受け付け期限を過ぎているため、サイト上ではキャンセルを行っていただけません。特別な事情がある場合、メールにてご連絡ください。",
    cancel: "キャンセルする",
    cancelFailedTitle: "キャンセルを受け付けられません",
    canceledTitle: "キャンセルを受け付けました",
    canceledMessage:
      "お申込みがキャンセルされました。ご登録のメールアドレス宛にデータミックスからメールをお送りするので、ご確認ください。",
    subscribeMessage:
      "この講座はキャンセル待ちが可能です。 admission@datamix.co.jp 宛にキャンセル待ちを希望される講座をお知らせください。",
    subscribable: "キャンセル待ち不可",
    notSubscribable: "キャンセル待ち可",
    emailOptIn: "ご案内メール配信可否",
    confirmCancel:
      "キャンセル後はキャンセルを取り消すことができませんので、ご注意ください。",
    reason: "キャンセル事由",
    reasonRequired: "キャンセル事由をご記入ください",
    invoice: "請求書",
    invoiceDownload: "請求書ダウンロード",
    receiptDownload: "領収書ダウンロード",
    invoiceDownloadInstruction1: "請求書に記載する各項目をご入力ください。",
    invoiceDownloadInstruction2:
      "※ 一度登録すると変更できませんのでご注意ください。",
    invoiceDownloadInstruction3: "※ ダウンロード自体は何度でも可能です。",

    receipt: "領収書",
    receiptDownloadInstruction1: "領収書に記載する各項目をご入力ください。",
    receiptDownloadInstruction2:
      "※ 一度登録すると変更できませんのでご注意ください。",
    receiptDownloadInstruction3: "※ ダウンロード自体は何度でも可能です。",
    name: "宛名",
    saveAndDownload: "保存してダウンロード",
    print: "印刷する",
    postcodeIcon: "〒",
    issueDate: "発行日付",
    dueDate: "お支払い期限",
    invoiceMessage:
      "毎度、格別のお引き立てに預かり、厚く御礼申し上げます。\n下記の通り、ご請求致しますので、よろしくお願い致します。",
    receiptMessage: "上記の金額を正に領収しました。",
    datamixPostcode: "〒 101-0051",
    datamixAddress1: "東京都千代田区神田神保町2-44",
    datamixAddress2: "第2石坂ビル2階",
    datamixName: "株式会社ピープルドット",
    datamixTel: "03-6272-3970",
    detail: "内訳詳細",
    detailName: "品名",
    detailPrice: "単価",
    detailAmount: "数量",
    detailPriceTotal: "金額",
    totalPrice: "合計（税込）",
    noteContentPrepaymentInvoice:
      "振込先:みずほ銀行 銀座支店(035) 普通 4069695 カ)ピープルドット\n※ 振込手数料はお客さまにてご負担ください。\n※ ご入金をもってお申込み確定となります。",
    noteContentPostpaymentInvoice:
      "振込先:みずほ銀行 銀座支店(035) 普通 4069695 カ)ピープルドット\n※ 振込手数料はお客さまにてご負担ください",
    orderId: "注文ID",
    tax: "消費税",
    tenPercentTarget: "10%対象",
    invoiceRegistrationNumber: "登録番号：T1011001114737",
    invoiceMonth: "ヶ月分",
    completing: " のお申込みを完了させています ...",
  },
  course: {
    myCourse: "マイ講座",
    coursesOpen: "開講中の講座",
    coursesToBeOpen: "開講予定の講座",
    coursesClose: "閉講済みの講座",
    coursesELearning: "E-Learning",
    toCanvas: "Canvasのページを開く",
    toSchedule: "スケジュールを開く",
    answer: "に回答する",
    reAnswer: "の回答を変更する",
    answered: "への回答を受け付けました",
    questionnaireAfterSession: "授業評価",
    notYetAttended: "（予約済）",
    transferSession: "曜日を振り替える",
    transferSessionShort: "曜日振替",
    cancel: "予約をキャンセルする",
    cancelShort: "キャンセル",
    confirmCancel:
      "これは授業に出席できなくなった方に予約をキャンセルいただくためのメニューです。キャンセル後はキャンセルを取り消すことができません。よろしいですか？",
    transferred: "（振替済）",
    canceled: "（キャンセル済）",
    cancelSuccessTitle: "授業をキャンセルしました",
    cancelSuccessContent:
      "ご登録のメールアドレス宛にデータミックスからメールをお送りするので、ご確認ください。",
    addAttend: "出席登録する",
    addAttendMessage: "授業への出席登録をして、出席済とします。よろしいですか?",
    attended: "（出席済）",
    transferInstruction: "振替先の授業をお選びください。",
    noTransferrableCourses: "振替先候補の授業がありません。",
    noRemainings: "満席のため振り替えいただけません",
    transferSuccessTitle: "授業を振り替えました",
    transferSuccessContent:
      "ご登録のメールアドレス宛にデータミックスからメールをお送りするので、ご確認ください。",
    transferFailTitle: "授業を振り替えられません",
    transferFailContent403:
      "申し訳ありません。満席のため振り替えいただけません。",
    transferFailContentDefault:
      "大変申し訳ありません、システムエラーが発生しております。現在対応中なので、復旧まで申し訳少々おまちください。",
    addAttendSuccessTitle: "出席を登録しました",
    addAttendSuccessMessage: "出席を登録しました。お疲れさまでした。",
    addAttendFailTitle: "出席登録に失敗しました。",
    addAttendFailMessage:
      "大変申し訳ありません、システムエラーが発生しております。現在対応中なので、復旧まで申し訳少々おまちください。教室にいらっしゃる場合、お手数ですがスタッフまでご報告頂けると幸いです。",
  },
  questionnaire: {
    answerBelow: "下記質問にご回答ください",
    answer: "回答する",
    answeredTitle: "ご回答を受け付けました。ありがとうございます。",
    answeredMessage: "",
    answerFailedTitle: "回答に失敗しました",
    answerFailedMessageDuplicated: "すでにご回答済みです。",
    answerFailedMessageServerError:
      "大変申し訳ありません、システムエラーが発生しております。現在対応中なので、復旧まで申し訳少々おまちください。教室にいらっしゃる場合、お手数ですがスタッフまでご報告頂けると幸いです。",
    backToMyCourse: "マイ講座に戻る",
    confirmAnswer: "回答を送信します。よろしいですか？",
  },
  footer: {
    copyright: "Copyright © 2024 PeopleDot Co., Ltd. All rights reserved.",
    hp: "ホームページ",
    news: "ニュース",
    blog: "ブログ",
    company: "会社概要",
    tokuteisyo: "特定商取引法に基づく表記",
    contact: "お問い合わせ",
    ikusei: "データサイエンティスト育成コース",
    junbi: "データサイエンティスト準備ステップ",
  },
};

export default dict;
