import { Box, CircularProgress, CssBaseline } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import React, { Fragment, useEffect, useState } from "react";
import { isIE } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GlobalStyle from "../../globalStyles";
import Dashboard from "../components/Dashboard";
import dict from "../dictionary";
import firebase from "../firebase";
import useUserStateContext from "../userstate";
import Auth from "./auth/Auth";
import Login from "./auth/Login";
import PasswordForgot from "./auth/PasswordForgot";
import Signup from "./auth/Signup";
import SignupComplete from "./auth/SignupComplete";
import SignupCompleteExistingStudent from "./auth/SignupCompleteExistingStudent";
import SignupExistingStudent from "./auth/SignupExistingStudent";
import VerificationExpired from "./auth/VerificationExpired";
import CourseDetail from "./course/CourseDetail";
import CourseList from "./course/CourseList";
import Footer from "./Footer";
import HeaderBar from "./HeaderBar";
import CancelComplete from "./order/CancelComplete";
import Invoice from "./order/Invoice";
import OrderComplete from "./order/OrderComplete";
import OrderConfirm from "./order/OrderConfirm";
import OrderDetail from "./order/OrderDetail";
import OrderList from "./order/OrderList";
import OrderOrderInfo from "./order/OrderOrderInfo";
import OrderStudentInfo from "./order/OrderStudentInfo";
import Receipt from "./order/Receipt";
import OrderCreate from "./OrderCreate";
import ProductDetailFree from "./product/ProductDetailFree";
import ProductDetailIkusei from "./product/ProductDetailIkusei";
import ProductDetailJunbi from "./product/ProductDetailJunbi";
import ProductDetailOther from "./product/ProductDetailOther";
import ProductDetailweather from "./product/ProductDetailweather";
import ProductDetailHR from "./product/ProductDetailHR";
import ProductDetailPDA from "./product/ProductDetailPDA";
import ProductDetailDataEng from "./product/ProductDetailDataEng";
import ProductDetailAIMLEng from "./product/ProductDetailAIMLEng";
import ProductDetailDSIkusei from "./product/ProductDetailDSIkusei";
import ProductList from "./product/ProductList";
import ProfileStudentInfo from "./profile/ProfileStudentInfo";
import Settings from "./profile/Settings";
import QuestionnaireAnswer from "./questionnaire/QuestionnaireAnswer";
import QuestionnaireAnswerComplete from "./questionnaire/QuestionnaireAnswerComplete";
import UserStateWtapper from "./UserStateWrapper";
import MAWrapper from "./MAWrapper";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#02B3E4",
    },
    secondary: {
      main: "#02e4a4",
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Noto Sans JP", sans-serif',
  },
  overrides: {
    MuiToolbar: {
      regular: {
        backgroundColor: "white",
        height: "72px",
        "@media(max-width:768px)": {
          height: "60px",
        },
        position: "relative",
      },
    },
    MuiInputBase: {
      input: {
        "&:disabled": {
          backgroundColor: "#fafafa",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "18.5px 24px",
      },
    },
  },
});

theme.shadows[4] =
  "0px 0px 1px -1px rgba(0,0,0,0.2), 0px 1px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)";

export const styles = (): StyleRules =>
  createStyles({
    body: {
      margin: 0,
      display: "flex",
      flexFlow: "column",
      minHeight: "100vh",
    },
    main: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  });

const App = (props: any) => {
  const { classes } = props;
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    firebase
      .isInitialized()
      .then((val) => {
        setFirebaseInitialized(true);
        firebase.getCurrentUser() === null
          ? setSignedIn(false)
          : setSignedIn(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (isIE) {
    return <>{dict.common.notForIE}</>;
  }

  return firebaseInitialized !== false ? (
    <useUserStateContext.Provider
      initialState={{ signedIn: signedIn, redirect: "" }}
    >
      <UserStateWtapper>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyle />
          <Router>
            <MAWrapper>
              <Box className={classes.body}>
                <Box className={classes.main}>
                  <Switch>
                    <Route exact path="/invoice/:orderId" component={Invoice} />
                    <Route exact path="/receipt/:orderId" component={Receipt} />
                    <Fragment>
                      <HeaderBar />
                      <Route exact path="/" component={ProductList} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/signup" component={Signup} />
                      <Route
                        exact
                        path="/signup-existing"
                        component={SignupExistingStudent}
                      />
                      <Route exact path="/auth" component={Auth} />
                      <Route
                        exact
                        path="/signup/complete"
                        component={SignupComplete}
                      />
                      <Route
                        exact
                        path="/signup-existing/complete"
                        component={SignupCompleteExistingStudent}
                      />
                      <Route
                        exact
                        path="/password-reset"
                        component={PasswordForgot}
                      />
                      <Route
                        exact
                        path="/verification-expired"
                        component={VerificationExpired}
                      />
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route exact path="/product" component={ProductList} />
                      <Route
                        exact
                        path="/profile"
                        component={ProfileStudentInfo}
                      />
                      <Route exact path="/settings" component={Settings} />
                      <Route
                        exact
                        path="/product/ikusei/:term"
                        component={ProductDetailIkusei}
                      />
                      <Route
                        exact
                        path="/product/ikusei/:term/:school"
                        component={ProductDetailIkusei}
                      />
                      <Route
                        exact
                        path="/product/weather/:term/:school"
                        component={ProductDetailweather}
                      />
                      <Route
                        exact
                        path="/product/hr/:term/:school"
                        component={ProductDetailHR}
                      />
                      <Route
                        exact
                        path="/product/pda/:term/:school"
                        component={ProductDetailPDA}
                      />
                      <Route
                        exact
                        path="/product/de/:term/:school"
                        component={ProductDetailDataEng}
                      />
                      <Route
                        exact
                        path="/product/mle/:term/:school"
                        component={ProductDetailAIMLEng}
                      />
                      <Route
                        exact
                        path="/product/ds-ikusei/:term/:school"
                        component={ProductDetailDSIkusei}
                      />
                      <Route
                        exact
                        path="/product/junbi/:term"
                        component={ProductDetailJunbi}
                      />
                      <Route
                        exact
                        path="/product/junbi/:term/:school"
                        component={ProductDetailJunbi}
                      />
                      <Route
                        exact
                        path="/product/:productId"
                        component={ProductDetailOther}
                      />
                      <Route
                        exact
                        path="/product/free/:freeType"
                        component={ProductDetailFree}
                      />
                      <Route
                        exact
                        path="/product/free/:freeType/:school"
                        component={ProductDetailFree}
                      />
                      <Route
                        exact
                        path="/order/basic-info"
                        component={OrderStudentInfo}
                      />
                      <Route
                        exact
                        path="/order/order-info"
                        component={OrderOrderInfo}
                      />
                      <Route
                        exact
                        path="/order/confirm"
                        component={OrderConfirm}
                      />
                      <Route
                        exact
                        path="/order/complete"
                        component={OrderComplete}
                      />
                      <Route
                        exact
                        path="/order/create/:productId"
                        component={OrderCreate}
                      />
                      <Route exact path="/order" component={OrderList} />
                      <Route
                        exact
                        path="/cancel/complete"
                        component={CancelComplete}
                      />
                      <Route
                        exact
                        path="/order-detail/:orderId"
                        component={OrderDetail}
                      />
                      <Route exact path="/course" component={CourseList} />
                      <Route
                        exact
                        path="/course/:courseId"
                        component={CourseDetail}
                      />
                      <Route
                        exact
                        path="/questionnaire/:questionnaireId"
                        component={QuestionnaireAnswer}
                      />
                      <Route
                        exact
                        path="/questionnaire/answer/complete"
                        component={QuestionnaireAnswerComplete}
                      />
                      <Footer />
                    </Fragment>
                  </Switch>
                </Box>
              </Box>
            </MAWrapper>
          </Router>
        </MuiThemeProvider>
      </UserStateWtapper>
    </useUserStateContext.Provider>
  ) : (
    <div id="loader">
      <CircularProgress />
    </div>
  );
};

export default withStyles(styles)(App);
