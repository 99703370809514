import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { categoryNamesDict, schoolNamesDict } from "../../../../enum";
import { stylesCommon, stylesList } from "../../css";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductCard from "./ProductCard";
import ProductCardFree from "./ProductCardFree";
import dict from "../../dictionary";
import { useGetProducts } from "../../fetch/product";
import { withRouter } from "react-router";

export const findSchoolName = (school: string) => {
  const schoolNameTmp = schoolNamesDict.filter(
    (schoolNameDict: { name: string; nameJa: string }) => {
      return schoolNameDict.name === school.replace(`school_`, ``);
    }
  );
  return schoolNameTmp.length > 0 ? schoolNameTmp[0].nameJa : null;
};

const ProductList = (props: any) => {
  const { classes } = props;
  const {
    getProducts,
    productsIkusei,
    productsWeather,
    productsHR,
    productsPDA,
    productsDataEng,
    productsAIMLEng,
    productsDSIkusei,
    productsJunbi,
    productsOther,
    productsFree,
  } = useGetProducts();
  const [schools, setSchools] = useState<string[]>([]);
  const [selectedSchools, setSelectedSchools] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({
    ikusei: false,
    junbi: false,
    other: false,
    free: false,
  });
  const [noCategorySelected, setNoCategorySelected] = useState<boolean>(true);
  const [mobileFilteringOpen, setMobileFilteringOpen] =
    useState<boolean>(false);
  const [query, setQuery] = useState(``);

  useEffect(() => {
    (async () => {
      await getProducts({});
    })();
    const queryTemp = new URLSearchParams(window.location.search).get("query");
    queryTemp && setQuery(queryTemp);
  }, []);

  useEffect(() => {
    const allProducts = productsIkusei
      .concat(productsWeather)
      .concat(productsHR)
      .concat(productsPDA)
      .concat(productsDataEng)
      .concat(productsAIMLEng)
      .concat(productsDSIkusei)
      .concat(productsJunbi)
      .concat(productsOther)
      .concat(productsFree);
    let schoolsTemp = allProducts
      .map((product: any) => {
        const tagTemp = product.tags.filter((tag: string) => {
          return tag.startsWith("school_");
        });
        return tagTemp.length > 0 ? tagTemp[0] : null;
      })
      .filter((x: any, i: any, self: string | any[]) => {
        return self.indexOf(x) === i;
      })
      .filter((school: string) => {
        return school != null;
      });
    setSchools(schoolsTemp);
  }, [
    productsIkusei,
    productsWeather,
    productsHR,
    productsPDA,
    productsDataEng,
    productsAIMLEng,
    productsDSIkusei,
    productsJunbi,
    productsOther,
    productsFree,
  ]);

  const extractTerms = (products: any[]) => {
    let terms: string[] = [];
    products.forEach((product: any) => {
      product.tags.forEach((tag: any) => {
        tag.includes("term_") && terms.push(tag);
      });
    });
    return Array.from(new Set(terms));
  };

  const extractTerm = (product: any) => {
    let term: any = null;
    product.tags.forEach((tag: any) => {
      term = tag.includes("term_") ? tag : null;
    });
    return term;
  };

  // filtering

  const handleSchoolFiltering = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    selectedSchools.includes(event.target.name)
      ? setSelectedSchools(
          selectedSchools.filter((selectedSchool: string) => {
            return selectedSchool != event.target.name;
          })
        )
      : setSelectedSchools([...selectedSchools, event.target.name]);
  };

  const getSelectedOrAllSchools = () => {
    return selectedSchools.length > 0
      ? selectedSchools
      : schools.map((school: string) => {
          return school;
        });
  };

  const filterProducts = (products: any, school?: string, term?: string) => {
    let filteredProducts = products;
    filteredProducts = school
      ? filteredProducts.filter((product: any) => {
          return product.tags.includes(school);
        })
      : filteredProducts;

    filteredProducts = term
      ? filteredProducts.filter((product: any) => {
          return product.tags.includes(term);
        })
      : filteredProducts;

    filteredProducts = query
      ? filteredProducts.filter((product: any) => {
          return (
            product.name?.toLowerCase().includes(query) ||
            product.description?.toLowerCase().includes(query)
          );
        })
      : filteredProducts;
    return filteredProducts;
  };

  const handleCategoryFiltering = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (selectedCategory[event.target.name] === true) {
      setSelectedCategory({ ...selectedCategory, [event.target.name]: false });
    } else {
      setSelectedCategory({ ...selectedCategory, [event.target.name]: true });
    }
  };

  useEffect(() => {
    let noneSelected = true;
    categoryNamesDict.forEach((categoryNameDict: any) => {
      noneSelected =
        selectedCategory[categoryNameDict.name] === true ? false : noneSelected;
    });
    setNoCategorySelected(noneSelected);
  }, [selectedCategory]);

  return (
    <Fragment>
      {/* <div className={classes.contents}></div> */}

      <Container maxWidth="xl" className={classes.productContainerInner}>
        <Grid container className={classes.mb4}>
          <Grid container>
            <Hidden mdUp>
              <Button
                fullWidth
                className={`${classes.mb2} ${classes.bgWhite} ${classes.borderRound}`}
                variant="contained"
                endIcon={
                  mobileFilteringOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                onClick={() => {
                  mobileFilteringOpen
                    ? setMobileFilteringOpen(false)
                    : setMobileFilteringOpen(true);
                }}
              >
                {dict.productList.filter}
              </Button>
            </Hidden>

            <Hidden smDown>
              <Grid container className={`${classes.mt4} ${classes.mb8}`}>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="outlined-basic"
                    label="講座を検索"
                    variant="outlined"
                    fullWidth
                    className={`${classes.searchInput}`}
                    value={query}
                    onChange={(event: any) => {
                      setQuery(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}></Grid>
              </Grid>
            </Hidden>

            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.p3} ${
                !mobileFilteringOpen && classes.mobileClose
              }`}
            >
              <Hidden mdUp>
                <TextField
                  id="outlined-basic"
                  label={dict.productList.search}
                  variant="outlined"
                  fullWidth
                  className={`${classes.mb5} ${classes.searchInput}`}
                  value={query}
                  onChange={(event: any) => {
                    setQuery(event.target.value);
                  }}
                />
              </Hidden>

              <FormControl
                required
                component="fieldset"
                className={`${classes.formControl} ${classes.mb3} ${classes.w100}`}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  color="textSecondary"
                  className={`${classes.bolder} ${classes.pl1} ${classes.filterHeader} ${classes.filterKind}`}
                >
                  {dict.product.kind}
                </Typography>
                <FormGroup>
                  {categoryNamesDict.length > 0 &&
                    categoryNamesDict.map(
                      (categoryNameDict: { name: string; nameJa: string }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleCategoryFiltering}
                                name={categoryNameDict.name}
                                color="primary"
                              />
                            }
                            label={categoryNameDict.nameJa}
                            className={classes.mt1}
                          />
                        );
                      }
                    )}
                </FormGroup>
              </FormControl>

              <FormControl
                required
                component="fieldset"
                className={`${classes.formControl} ${classes.w100}`}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  color="textSecondary"
                  className={`${classes.bolder} ${classes.pl1} ${classes.filterHeader} ${classes.filterPlace}`}
                >
                  {dict.common.holdingSchool}
                </Typography>
                <FormGroup>
                  {schools.length > 0 &&
                    schools
                      .filter((school: string) => {
                        return school != null;
                      })
                      .map((school: string) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleSchoolFiltering}
                                name={school}
                                color="primary"
                              />
                            }
                            label={findSchoolName(school)}
                            className={classes.mt1}
                          />
                        );
                      })}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={9}>
              {productsIkusei.length === 0 &&
                productsWeather.length === 0 &&
                productsHR.length === 0 &&
                productsPDA.length === 0 &&
                productsDataEng.length === 0 &&
                productsAIMLEng.length === 0 &&
                productsDSIkusei.length === 0 &&
                productsJunbi.length === 0 &&
                productsOther.length === 0 &&
                productsFree.length === 0 && (
                  <Box className={classes.p2}>
                    <CircularProgress />
                  </Box>
                )}

              {/* ikusei */}

              {extractTerms(productsIkusei).length > 0 &&
                extractTerms(productsIkusei).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsIkusei, term, school).length > 0 &&
                      (noCategorySelected || selectedCategory["ikusei"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(
                            productsIkusei,
                            term,
                            school
                          )}
                          productName={dict.product.ikuseiTitle}
                          href="ikusei"
                          description={
                            filterProducts(productsIkusei, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {/* weather */}

              {extractTerms(productsWeather).length > 0 &&
                extractTerms(productsWeather).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsWeather, term, school).length >
                        0 &&
                      (noCategorySelected || selectedCategory["weather"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(
                            productsWeather,
                            term,
                            school
                          )}
                          productName={dict.product.weatherTitle}
                          href="weather"
                          description={
                            filterProducts(productsWeather, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {/* hr */}

              {extractTerms(productsHR).length > 0 &&
                extractTerms(productsHR).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsHR, term, school).length > 0 &&
                      (noCategorySelected || selectedCategory["hr"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(productsHR, term, school)}
                          productName={dict.product.hrTitle}
                          href="hr"
                          description={
                            filterProducts(productsHR, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {/* pda */}

              {extractTerms(productsPDA).length > 0 &&
                extractTerms(productsPDA).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsPDA, term, school).length > 0 &&
                      (noCategorySelected || selectedCategory["pda"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(productsPDA, term, school)}
                          productName={dict.product.pdaTitle}
                          href="pda"
                          description={
                            filterProducts(productsPDA, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {/* de */}

              {extractTerms(productsDataEng).length > 0 &&
                extractTerms(productsDataEng).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsDataEng, term, school).length > 0 &&
                      (noCategorySelected || selectedCategory["de"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(productsDataEng, term, school)}
                          productName={dict.product.dataEngTitle}
                          href="de"
                          description={
                            filterProducts(productsDataEng, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {/* mle */}

              {extractTerms(productsAIMLEng).length > 0 &&
                extractTerms(productsAIMLEng).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsAIMLEng, term, school).length > 0 &&
                      (noCategorySelected || selectedCategory["mle"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(productsAIMLEng, term, school)}
                          productName={dict.product.aimlEngTitle}
                          href="mle"
                          description={
                            filterProducts(productsAIMLEng, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {/* ds-ikusei */}

              {extractTerms(productsDSIkusei).length > 0 &&
                extractTerms(productsDSIkusei).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      filterProducts(productsDSIkusei, term, school).length > 0 &&
                      (noCategorySelected || selectedCategory["ds-ikusei"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(productsDSIkusei, term, school)}
                          productName={dict.product.dsikuseiTitle}
                          href="ds-ikusei"
                          description={
                            filterProducts(productsDSIkusei, term, school)[0]
                              .description
                          }
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {extractTerms(productsJunbi).length > 0 &&
                extractTerms(productsJunbi).map((term: string) =>
                  getSelectedOrAllSchools().map((school: string) => {
                    if (
                      (filterProducts(productsJunbi, term, school).length > 0 &&
                        noCategorySelected) ||
                      (filterProducts(productsJunbi, term, school).length > 0 &&
                        selectedCategory["junbi"])
                    ) {
                      return (
                        <ProductCard
                          products={filterProducts(productsJunbi, term, school)}
                          productName={dict.product.junbiTitle}
                          href="junbi"
                          description={dict.product.junbiDescription}
                          term={term}
                          school={school}
                        />
                      );
                    }
                  })
                )}

              {selectedSchools.length > 0
                ? productsOther.length > 0 &&
                  filterProducts(productsOther).map((product: any) =>
                    noCategorySelected
                      ? getSelectedOrAllSchools().map(
                          (school: string) =>
                            product.tags.includes(school) && (
                              <ProductCard
                                product={product}
                                products={[]}
                                productName={product.name}
                                href={product.id}
                                description={product.description}
                                term={extractTerm(product)}
                              />
                            )
                        )
                      : getSelectedOrAllSchools().map(
                          (school: string) =>
                            product.tags.includes(school) &&
                            selectedCategory["other"] && (
                              <ProductCard
                                product={product}
                                products={[]}
                                productName={product.name}
                                href={product.id}
                                description={product.description}
                                term={extractTerm(product)}
                              />
                            )
                        )
                  )
                : noCategorySelected
                ? filterProducts(productsOther).map((product: any) => (
                    <ProductCard
                      product={product}
                      products={[]}
                      productName={product.name}
                      href={product.id}
                      description={product.description}
                      term={extractTerm(product)}
                    />
                  ))
                : filterProducts(productsOther).map(
                    (product: any) =>
                      selectedCategory["other"] && (
                        <ProductCard
                          product={product}
                          products={[]}
                          productName={product.name}
                          href={product.id}
                          description={product.description}
                          term={extractTerm(product)}
                        />
                      )
                  )}

              {selectedSchools.length > 0
                ? productsFree.length > 0 &&
                  filterProducts(productsFree).map((product: any) =>
                    noCategorySelected
                      ? getSelectedOrAllSchools().map(
                          (school: string) =>
                            product.tags.includes(school) && (
                              <ProductCardFree
                                product={product}
                                school={school}
                              />
                            )
                        )
                      : getSelectedOrAllSchools().map(
                          (school: string) =>
                            product.tags.includes(school) &&
                            selectedCategory["free"] && (
                              <ProductCardFree
                                product={product}
                                school={school}
                              />
                            )
                        )
                  )
                : noCategorySelected
                ? filterProducts(productsFree).map((product: any) => (
                    <ProductCardFree product={product} />
                  ))
                : filterProducts(productsFree).map(
                    (product: any) =>
                      selectedCategory["free"] && (
                        <ProductCardFree product={product} />
                      )
                  )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesList)(withRouter(ProductList))
);
