import * as Yup from "yup";

import {
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { stylesCommon, stylesOrder } from "../../css";

import MessageDialog from "../MessageDialog";
import OrderStepper from "./OrderStepper";
import { TextField } from "formik-material-ui";
import dict from "../../dictionary";
import { formatDate } from "../../util";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";

const OrderOrderInfo = (props: any) => {
  const { classes } = props;
  const { userState, setRedirect } = useUserStateContext();

  const [open, setOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const OrderSchema = Yup.object().shape({
    payment_name: Yup.string().when("oneself", {
      is: false,
      then: Yup.string().required(dict.form.required),
    }),
    postcode: Yup.string().when(["prepayment", "oneself"], {
      is: false,
      then: Yup.string().required(dict.form.required),
    }),
    address: Yup.string().when(["prepayment", "oneself"], {
      is: false,
      then: Yup.string().required(dict.form.required),
    }),
  });

  useEffect(() => {
    if (userState.signedIn === false) {
      setRedirect(window.location.pathname);
      props.history.push("/login");
    }
    !props.location.state && props.history.push("/");
  }, [userState, props.history]);

  return (
    <Fragment>
      <Box className={classes.box}>
        <Container maxWidth="lg">
          <OrderStepper activeStep={1} />
        </Container>
      </Box>
      <Container maxWidth="lg" className={classes.mb4}>
        <Typography variant="h6" className={classes.p2}>
          {dict.order.orderInfo}
        </Typography>
        <Paper elevation={0} square>
          <Formik
            initialValues={
              // kyuufukin
              props.location.state.product.tags.includes("ikusei") ||
              props.location.state.product.tags.includes("weather") ||
              props.location.state.product.tags.includes("hr") ||
              props.location.state.product.tags.includes("pda") ||
              props.location.state.product.tags.includes("ds-ikusei")
                ? {
                    prepayment: true,
                    oneself: true,
                    payment_name: "",
                    postcode: "",
                    address: "",
                    kyufukin: false,
                    note: "",
                    student_name:
                      props.location.state.me.last_name +
                      " " +
                      props.location.state.me.first_name,
                    student_address: props.location.state.me.address,
                  }
                : {
                    prepayment: true,
                    oneself: true,
                    payment_name: "",
                    postcode: "",
                    address: "",
                    note: "",
                    student_name:
                      props.location.state.me.last_name +
                      " " +
                      props.location.state.me.first_name,
                    student_address: props.location.state.me.address,
                  }
            }
            validationSchema={OrderSchema}
            onSubmit={async (values) => {
              // https://docs.google.com/spreadsheets/d/1KnpnwIUOddTN9AHbPYng0yBQ7C2-9DHWZmLgq45Z_Ak/edit#gid=0
              const invoice = values.prepayment
                ? {
                    name: null,
                    postcode: null,
                    address: null,
                  }
                : null;
              const receipt = null;

              props.history.push({
                pathname: `/order/confirm`,
                state: {
                  product: props.location.state.product,
                  me: props.location.state.me,
                  order: values,
                  invoice: invoice,
                  receipt: receipt,
                },
              });
            }}
            render={({
              submitForm,
              isSubmitting,
              values,
              setFieldValue,
              errors,
              isValid,
            }) => (
              <Fragment>
                <Form className={classes.form}>
                  <Grid container className={classes.mb3}>
                    <Grid item xs={12} md={3} className={classes.mb1}>
                      <Typography className={classes.mt1}>
                        {dict.order.orderInfo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="prepayment"
                          name="prepayment"
                          onChange={(event: any) => {
                            // value strangely gets string
                            if (
                              (event.target as HTMLInputElement).value ===
                              "true"
                            ) {
                              setFieldValue("prepayment", true);
                            } else {
                              setFieldValue("prepayment", false);
                              props.location.state.product.price < 100000 &&
                                values.oneself === true &&
                                setFieldValue("oneself", false);
                            }
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label={dict.order.prepayment}
                            checked={values.prepayment}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={dict.order.nonPrepayment}
                            checked={!values.prepayment}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.mb3}>
                    <Grid item xs={12} md={3} className={classes.mb1}>
                      <Typography className={classes.mt1}>
                        {dict.order.paymentName}
                      </Typography>
                    </Grid>
                    {values.prepayment ? (
                      <Grid item xs={12} md={9}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="oneself"
                            name="oneself"
                            onChange={(event: any) => {
                              (event.target as HTMLInputElement).value ===
                              "true"
                                ? setFieldValue("oneself", true)
                                : setFieldValue("oneself", false);
                            }}
                            className={classes.mb1}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label={dict.order.oneself}
                              checked={values.oneself}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={dict.order.nonOneself}
                              checked={!values.oneself}
                            />
                          </RadioGroup>
                        </FormControl>
                        <Field
                          name="payment_name"
                          label={dict.order.nameOrCompanyName}
                          type="text"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          disabled={values.oneself}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={9}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="oneself"
                            name="oneself"
                            onChange={(event: any) => {
                              (event.target as HTMLInputElement).value ===
                              "true"
                                ? setFieldValue("oneself", true)
                                : setFieldValue("oneself", false);
                            }}
                            className={classes.mb1}
                          >
                            {props.location.state.product.price >= 100000 && (
                              <Fragment>
                                <FormControlLabel
                                  value={true}
                                  control={<Radio color="primary" />}
                                  label={dict.order.oneself}
                                  checked={values.oneself}
                                />
                                <Typography
                                  variant="caption"
                                  className={classes.lineBreak}
                                >
                                  {dict.order.oneselfInstruction}
                                </Typography>
                              </Fragment>
                            )}
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={dict.order.company}
                              checked={!values.oneself}
                              onChange={(event: any, checked: boolean) => {
                                if (
                                  props.location.state.product.tags.includes(
                                    "type_all"
                                  ) &&
                                  checked
                                ) {
                                  values.kyufukin = false;
                                }
                              }}
                            />
                            <Typography
                              variant="caption"
                              className={classes.lineBreak}
                            >
                              {dict.order.nonOneselfInstruction}
                            </Typography>
                          </RadioGroup>
                        </FormControl>
                        <Field
                          name="payment_name"
                          label={dict.order.paymentNameCompany}
                          type="text"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          disabled={values.oneself}
                          className={classes.mb2}
                        />
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          className={classes.mb1}
                        >
                          <Grid item xs={7} md={4}>
                            <Field
                              name="postcode"
                              label={dict.order.postcode}
                              type="string"
                              variant="outlined"
                              fullWidth
                              component={TextField}
                              disabled={values.oneself}
                            />
                          </Grid>
                          <Grid item xs={5} md={3}>
                            <Button
                              variant="contained"
                              color="default"
                              size="large"
                              disabled={values.oneself}
                              onClick={async () => {
                                let postcode = values.postcode.replace(
                                  /[Ａ-Ｚａ-ｚ０-９]/g,
                                  function (s: any) {
                                    return String.fromCharCode(
                                      s.charCodeAt(0) - 65248
                                    );
                                  }
                                );
                                postcode = postcode.replace("-", "");
                                postcode = postcode.replace("ー", "");
                                await fetch(
                                  `https://api.zipaddress.net/?zipcode=${postcode}`
                                )
                                  .then(function (response) {
                                    return response.json();
                                  })
                                  .then(function (resJson) {
                                    const resCode = resJson.code;
                                    setMessageTitle(
                                      dict.order.fetchAddressFailedTitle
                                    );
                                    switch (resCode) {
                                      case 200:
                                        setFieldValue(
                                          "address",
                                          resJson.data.fullAddress
                                        );
                                        break;
                                      case 400:
                                        setMessageContent(
                                          dict.order
                                            .fetchAddressFailedMessage400
                                        );
                                        setOpen(true);
                                        break;
                                      case 404:
                                        setMessageContent(
                                          dict.order
                                            .fetchAddressFailedMessage404
                                        );
                                        setOpen(true);
                                        break;
                                      default:
                                        setMessageContent(resJson.message);
                                        setOpen(true);
                                    }
                                  });
                              }}
                            >
                              {dict.order.searchAddress}
                            </Button>
                          </Grid>
                        </Grid>

                        <Field
                          name="address"
                          label={dict.common.address}
                          type="text"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          disabled={values.oneself}
                          // className={classes.mb2}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {(// kyuufukin
                    props.location.state.product.tags.includes("ikusei") ||
                    props.location.state.product.tags.includes("weather") ||
                    props.location.state.product.tags.includes("hr") ||
                    props.location.state.product.tags.includes("pda") ||
                    props.location.state.product.tags.includes("ds-ikusei")
                  ) && (
                    <Grid container className={classes.mb3}>
                      <Grid item xs={12} md={3} className={classes.mb1}>
                        <Typography className={classes.kyufukinLabel}>
                          {dict.order.kyufukin}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="kyufukin"
                            name="kyufukin"
                            onChange={(event: any) => {
                              if (
                                (event.target as HTMLInputElement).value ===
                                "true"
                              ) {
                                setFieldValue("kyufukin", true);
                              } else {
                                setFieldValue("kyufukin", false);
                              }
                            }}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio color="primary" />}
                              label={dict.order.requestKyufukin}
                              checked={values.kyufukin}
                              disabled={
                                !props.location.state.product.tags.includes(
                                  "type_all"
                                ) ||
                                (!values.prepayment && !values.oneself) ||
                                props.location.state.product.price < 675000
                              }
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio color="primary" />}
                              label={dict.order.notRequestKyufukin}
                              checked={!values.kyufukin}
                            />
                          </RadioGroup>
                        </FormControl>

                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.kyufukin}
                              onChange={() => {
                                values.kyufukin
                                  ? setFieldValue("kyufukin", false)
                                  : setFieldValue("kyufukin", true);
                              }}
                              value="kyufukin"
                              color="primary"
                            />
                          }
                          label={dict.order.requestKyufukin}
                        /> */}
                      </Grid>
                      <Grid item xs={12} md={3}></Grid>
                      <Grid item xs={12} md={9}>
                        <Typography
                          variant="caption"
                          className={classes.lineBreak}
                        >
                          {dict.order.kyufukinInstruction}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container className={classes.mb3}>
                    <Grid item xs={12} md={3} className={classes.mb1}></Grid>
                    <Grid item xs={12} md={9}>
                      <Paper
                        elevation={0}
                        style={{ backgroundColor: "#fafafa" }}
                        className={classes.p3}
                      >
                        <Typography
                          variant="caption"
                          className={classes.lineBreak}
                        >
                          {dict.order.aboutInvoiceAndReceipt}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.mb3}>
                    <Grid item xs={12} md={3} className={classes.mb1}>
                      <Typography className={classes.mtb1}>
                        {dict.order.note}
                      </Typography>
                      <Typography className={classes.pr2} variant="subtitle2">
                        {dict.order.noteInstruction}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Field
                        name="note"
                        // label={dict.order.note}
                        type="text"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        multiline
                      />
                    </Grid>
                  </Grid>

                  {isSubmitting && <LinearProgress />}
                  <Grid
                    container
                    justify="center"
                    className={classes.mt3}
                    spacing={3}
                  >
                    <Grid item sm={3} xs={6}>
                      <Fab
                        className={classes.defaultFab}
                        variant="extended"
                        onClick={() => {
                          props.history.goBack();
                        }}
                        size="large"
                        color="default"
                      >
                        {dict.common.back}
                      </Fab>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <Fab
                        className={classes.primaryFabMb0}
                        disabled={
                          isSubmitting || Object.keys(errors).length !== 0
                        }
                        type="submit"
                        variant="extended"
                        onClick={async (event) => {
                          submitForm();
                        }}
                        size="large"
                        color="primary"
                      >
                        {dict.common.next}
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              </Fragment>
            )}
          />
        </Paper>
      </Container>
      <MessageDialog
        open={open}
        handleClose={handleClose}
        messageTitle={messageTitle}
        messageContent={messageContent}
        nextUrl={null}
        actionButtonCaption={null}
        history={null}
      />
    </Fragment>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesOrder)(withRouter(OrderOrderInfo))
);
