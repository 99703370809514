import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { stylesCommon, stylesFooter } from "../css";

import dict from "../dictionary";
import useUserStateContext from "../userstate";
import { withRouter } from "react-router";

const Footer = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();

  return (
    <div className={classes.marginTopAuto}>
      <Divider className={classes.containerOuter} />
      <Box>
        <Container maxWidth="md">
          <Grid container className={classes.containerInner}>
            {[
              [dict.footer.hp, "https://datamix.co.jp/"],
              [dict.footer.company, "https://datamix.co.jp/corp/outline/"],
              [
                dict.footer.tokuteisyo,
                "https://datamix.co.jp/corp/transaction/",
              ],
              [dict.footer.contact, "https://datamix.co.jp/inquiry/"],
              // [dict.footer.ikusei, "https://datamix.co.jp/data-scientist/"],
              // [
              //   dict.footer.junbi,
              //   "https://datamix.co.jp/introductory-data-scientist-course/"
              // ]
            ].map((values: any) => {
              return (
                <Grid item xs={6} md={3} alignContent="center">
                  <Typography
                    align="center"
                    variant="subtitle2"
                    className={classes.mtb1}
                  >
                    <Link href={values[1]} target="_blank" rel="noopener">
                      {values[0]}
                    </Link>
                  </Typography>
                </Grid>
              );
            })}
            <Hidden mdUp>
              {userState.signedIn === false && (
                <Fragment>
                  <Grid item xs={6} md={3} alignContent="center">
                    <Typography
                      align="center"
                      variant="subtitle2"
                      className={classes.mtb1}
                    >
                      <Link href={"./signup"} rel="noopener">
                        {dict.common.signup}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} alignContent="center">
                    <Typography
                      align="center"
                      variant="subtitle2"
                      className={classes.mtb1}
                    >
                      <Link href={"./login"} target="_blank" rel="noopener">
                        {dict.common.login}
                      </Link>
                    </Typography>
                  </Grid>
                </Fragment>
              )}
            </Hidden>
            <Grid item xs={12} md={12}>
              <Typography
                align="center"
                variant="subtitle2"
                className={classes.mtb1}
              >
                {dict.footer.copyright}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default withStyles(stylesCommon)(
  withStyles(stylesFooter)(withRouter(Footer))
);
